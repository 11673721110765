import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'

import servicesData from '../../content/services.json';


class Services extends React.Component {
	constructor(props){
		super(props)
		console.log(servicesData);
		this.state = {
			services: servicesData.services,
			active_service: {title:'', description_html:''}
		}
	}
	componentDidMount(){
		this.setState({active_service:this.state.services[0]});
	}
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Services" />
        <div>
			<section className="hero services" style={{backgroundImage: "url('/img/services-header.jpg')"}}>
				<div className="container py-5">
					<div className="row short-top-hero align-items-end">
						<div className="col-md-10">
							<h1 className="h1 pt-5 pl-3 pl-md-5">We believe superior representation <br/>requires the highest level of <br/>commitment. </h1>							
						</div>
						<div className="col-md-2 text-right">			
							<Link className="mr-3" to="/contact/"><img className="icon" src="/img/icons/white-whatsapp.png" alt=""/></Link>
							<a target="_blank" href="https://twitter.com/GHW_barristers"><img className="icon" src="/img/icons/white-twitter.png" alt=""/></a><br/>	
						</div>	
					</div>
				</div>
			</section>
			<section className="bg-black fade-black-top py-5">
				<div className="container">
					<div className="row">
						<div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-white my-md-5">
							<p >We are a team of highly skilled and experienced criminal and regulatory lawyers who regularly appear before trial and appellate courts and tribunals across Canada. </p>

							<p>Whether you are charged with a criminal offence, facing professional discipline or administrative penalty, or in need of legal advice, we can help. Our lawyers share the same core litigation values, including thorough preparation, creative analysis, passionate advocacy, discretion and professionalism. </p>
						</div>
					</div>
				</div>
			</section>
			<section className="bg-primary text-white py-5">
				<div className="container">
					<div className="row">
						<div className="col-md-7">
							<h2 className="h1 my-5">Our skilled lawyers excel across a range of services.</h2>
							<Link to="/contact" className="btn btn-outline-white">Speak to a lawyer</Link>
							
						</div>
					</div>
				</div>
			</section>
			<section className="services bg-black py-5">
				<div className="container">
					<div className="row">
						<div className="col-md-12 bg-white py-5">
							<div className="row">
								<div className="col-md-4 offset-md-1">
									<h4 className="text-primary">What we do</h4>
									{this.state.services.map((this_service)=>(
										<button onClick={() => {this.setState({active_service:this_service})}} className="btn btn-link d-flex">
											<span className="mr-3">{this_service.id === this.state.active_service.id ? '-' : '+'}</span>
											<div className="mt-1">{this_service.title}</div>
										</button>
									))}
									
								</div>
								<div className="col-md-1"></div>
								<div className="col-md-5">
									<div className="mt-4" dangerouslySetInnerHTML={{ __html: this.state.active_service.description_html }} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
        </div>
      </Layout>
    )
  }
}

export default Services